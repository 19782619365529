<template>
    <div class="container">
        <b-row class="justify-content-center" v-if="logType && logType.log_type == 'wallet'">
            <b-col cols="3">
                <b-card>
                    <h3 class="font-weight-bolder text-center">User</h3>
                    <b-avatar size="88px" :src="logType.additional_info.user.thumbnail" />
                    <span class="ml-1 text-secondary">
                        {{ logType.additional_info.user.name }}
                    </span>
                </b-card>
            </b-col>
            <b-col cols="5">
                <b-card class="text-center">
                    <h3 class="font-weight-bolder mb-2 text-center">Wallet ballance</h3>
                    <span class="wallet-balance"> {{ logType.additional_info.wallet_balance.NojoumCoin }} <b-avatar class="mb-50" src="./images/COIN.png" /> </span>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="justify-content-center" v-if="logType && logType.log_type == 'transaction'">
            <b-col cols="3">
                <b-card>
                    <h3 class="font-weight-bolder text-center">From user</h3>
                    <b-avatar size="88px" :src="logType.additional_info.causer.thumbnail" />
                    <span class="ml-1 text-secondary">
                        {{ logType.additional_info.causer.name }}
                    </span>
                </b-card>
            </b-col>
            <b-col cols="7">
                <b-card>
                    <b-row class="mb-3 justify-content-center">
                        <b-col class="text-center">
                            <h3 class="font-weight-bolder">To user</h3>

                            <b-avatar size="88px" :src="logType.user.thumbnail" />
                            <span class="ml-1 text-secondary">
                                {{ logType.user.name }}
                            </span>
                        </b-col>
                    </b-row>
                    <hr />
                    <b-row class="mb-2 justify-content-center">
                        <b-col class="text-center">
                            <h4>Amount</h4>
                            <b-row class="justify-content-center">
                                <h2 class="text-warning mt-25">{{ logType.additional_info.amount_in_wallet_currency_type }}</h2>
                                <b-avatar class="mb-50 ml-50" src="./images/COIN.png" />
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <b-col class="text-center" cols="4">
                            <h5>Transaction status</h5>
                            <b-badge variant="light-success"> {{ logType.additional_info.transaction_status }} </b-badge>
                        </b-col>
                        <b-col class="text-center" cols="4">
                            <h5>Transaction type</h5>
                            <b-badge variant="light-primary"> {{ logType.additional_info.transaction_type }} </b-badge>
                        </b-col>
                        <b-col class="text-center" cols="4">
                            <h5>Transaction time</h5>
                            <b-row class="justify-content-center">
                                <b-badge variant="light-secondary"> {{ logType.additional_info.transaction_time.date }} </b-badge>
                            </b-row>
                            <b-row class="mt-25 justify-content-center">
                                <b-badge variant="light-secondary">
                                    {{ logType.additional_info.transaction_time.time }}
                                </b-badge>
                            </b-row>
                            <b-row class="mt-25 justify-content-center">
                                <b-badge variant="light-secondary">
                                    {{ logType.additional_info.transaction_time.timezone }}
                                </b-badge>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center mt-2">
                        <b-col class="text-center" cols="4">
                            <h5>Wallet balance before</h5>
                            <h2 class="text-danger mt-25">{{ logType.additional_info.wallet_balance_before }}</h2>
                        </b-col>
                        <b-col class="text-center" cols="4">
                            <h5>Wallet balance after</h5>
                            <h2 class="text-success mt-25">{{ logType.additional_info.wallet_balance_after }}</h2>
                        </b-col>
                        <b-col class="text-center" cols="4">
                            <h5>Wallet currency type</h5>
                            <b-row class="justify-content-center">
                                <b-badge variant="light-info"> {{ logType.additional_info.wallet_currency_type }} </b-badge>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="justify-content-center" v-if="logType && logType.log_type == 'payment'">
            <b-col cols="3">
                <b-card>
                    <h3 class="font-weight-bolder text-center">From user</h3>
                    <b-avatar size="88px" :src="logType.additional_info.causer.thumbnail" />
                    <span class="ml-1 text-secondary">
                        {{ logType.additional_info.causer.name }}
                    </span>
                </b-card>
            </b-col>
            <b-col cols="7">
                <b-card>
                    <b-row class="mb-3 justify-content-center">
                        <b-col class="text-center">
                            <h3 class="font-weight-bolder">To user</h3>

                            <b-avatar size="88px" :src="logType.user.thumbnail" />
                            <span class="ml-1 text-secondary">
                                {{ logType.user.name }}
                            </span>
                        </b-col>
                    </b-row>
                    <hr />
                    <b-row class="mb-2 justify-content-center">
                        <b-col class="text-center">
                            <h4>Amount</h4>
                            <b-row class="justify-content-center">
                                <h2 class="text-warning mt-25">{{ logType.additional_info.amount }}</h2>
                                <b-avatar class="mb-50 ml-50" src="./images/COIN.png" />
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <b-col class="text-center" cols="4">
                            <h5>Transaction status</h5>
                            <b-badge variant="light-success"> {{ logType.additional_info.payment_status }} </b-badge>
                        </b-col>
                        <b-col class="text-center" cols="4">
                            <h5>Payment type</h5>
                            <b-badge variant="light-primary"> {{ logType.additional_info.payment_type }} </b-badge>
                        </b-col>
                        <b-col class="text-center" cols="4">
                            <h5>Transaction time</h5>
                            <b-row class="justify-content-center">
                                <b-badge variant="light-secondary"> {{ logType.additional_info.transaction_time.date }} </b-badge>
                            </b-row>
                            <b-row class="mt-25 justify-content-center">
                                <b-badge variant="light-secondary">
                                    {{ logType.additional_info.transaction_time.time }}
                                </b-badge>
                            </b-row>
                            <b-row class="mt-25 justify-content-center">
                                <b-badge variant="light-secondary">
                                    {{ logType.additional_info.transaction_time.timezone }}
                                </b-badge>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center mt-2">
                        <b-col class="text-center" cols="4">
                            <h5>Wallet balance</h5>
                            <b-row class="justify-content-center mt-1">
                                <h2 class="text-warning mt-25">{{ logType.additional_info.wallet.wallet_balance.NojoumCoin }}</h2>
                                <b-avatar class="mb-50 ml-50" src="./images/COIN.png" />
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BAvatar, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormRadioGroup, BFormCheckbox, BBadge } from "bootstrap-vue";
import { ref, onUnmounted, onMounted, watch } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import useWalletList from "./useWalletList";
export default {
    components: {
        BBadge,
        BAvatar,
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BFormRadioGroup,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    setup() {
        const logId = ref(router.currentRoute.params.id);
        const logType = ref();
        const walletCurrencyBalance = ref();
        const walletCurrencyBalanceOption = ref();
        const { fetchAdminLogDetails } = useWalletList();
        fetchAdminLogDetails(logId.value).then((res) => {
            logType.value = res.data.data;
            console.log(logType.value);
        });
        return {
            logId,
            logType,
            walletCurrencyBalance,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.wallet-balance {
    font-size: 50px;
    margin-top: 60px;
}
</style>
